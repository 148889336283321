.footer{
    display: grid;
    place-items: center;
    width: 100%;
    height: 10vh;
    background: rgb(0, 0, 0);
    color: #fff;
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
    
}
.footer__container{
     display:flex;
    justify-content: space-between;
    align-items: center;
    transition: var(--transition)  

   
  
}
.footer__logo a:nth-child(1){
    margin-left: 0;
}
.footer__logo a {
    color: #fff;
    transition: var(--transition);
    margin-left: 1.5rem;
    
}




.footer__logo a:hover{

   
    
    color: var(--primary-color);

    
}


/* .footer__logo:hover{
    color: #a85d68;
} */


 @media screen  and (max-width: 680px) {
        .footer__container{
         flex-direction: column-reverse;

        }
}

