
*{
  margin:0;
  padding:0;
  border:0;
  outline:0;
  list-style:none;
  text-decoration: none;
  box-sizing: border-box;
}
:root{
  --transition: all 500ms ease;

  --border-radius-1: 1rem;
  --border-radius-2: 6px;

  --container-width-lg: 76%;
  --container-width-md: 90%;
  --font-heading: 'Space Grotesk', serif;
  --font-body:'Scope One',sans-serif;
  --primary-color: #a01025
}

body {
  margin: 0;
  font-family: 'Scope One', serif;
  
  
}

.background{
  background: #16242b ;
}
.container{
  width: var(--container-width-lg);
  margin-inline: auto;
  max-width: 1920px;  
 
}
.btn{
  display:inline-block;
  width:fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: Transparent;
 
  padding:0.6rem 2.5rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius-2);
  font-family: 'Anonymous Pro', monospace;
}

.btn.sm{
  padding:.5rem 1.2rem;
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  body {font-size: 1rem;}
  .container{width:var(--container-width-md);}
  
  
   p {
      width:72%;
      margin-bottom: 3rem;
  }
  h1{font-size: 2rem;}
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  h1 {font-size: 2.4rem;}
  h2 {font-size: 1.8rem;}
  .container{
    width: var(--container-width-md);
    margin-inline: auto;   
  }
  
  
}

